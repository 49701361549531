import { Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import useAdminRefreshTokenLikeLogin from '../hooks/useAdminRefreshTokenLikeLogin';
import useAuth from '../hooks/useAppContext';
import { clearLogin, clearSearch } from '../browser/localStorage';

//if one tab is logged in, all other tabs are logged in
export default function PersistLogin() {
    const [isLoading, setIsLoading] = useState(true);
    const adminRefreshLikeLogin = useAdminRefreshTokenLikeLogin();
    const { auth } = useAuth();

    useEffect(() => {
        const verifyRefreshToken = async () => {
            try {
                await adminRefreshLikeLogin();
            }
            catch (err) {
                console.log(err);
                // following is optional because the page like /orders it goes to will send 
                // a request with a 403 error, then interceipted and send a /refresh request
                // with 401 error
                clearLogin();
                clearSearch();
            }
            finally {
                setIsLoading(false);
            }
        }

        !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false);
    }, [])

    return (
        <>
            {isLoading
                ? <p> Loading...</p>
                : <Outlet />
            }
        </>
    )
}