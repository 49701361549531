import React, { useState } from 'react';
import { Box, IconButton, Popover, ListItem, ListItemButton, ListItemText } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import useAppContext from '../../hooks/useAppContext';
import StoreService from "../../services/StoreService";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from 'react-router-dom';
import notifyError from '../../errors/notifyError';
import { ConfirmDialog } from '../../components/common';

function ActionPopover({ store, records, setRecords, setNotify }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { auth } = useAppContext();
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });

    const open = Boolean(anchorEl);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDeleteStoreInfo = async id => {
        try {
            await StoreService.removeStoreInfo(axiosPrivate, id);

            setConfirmDialog({
                ...confirmDialog,
                isOpen: false
            })
            setNotify({
                isOpen: true,
                message: 'Deleted Successfully',
                type: 'error'
            })
        } catch (err) {
            setConfirmDialog({
                ...confirmDialog,
                isOpen: false
            })
            notifyError(err, setNotify, navigate, location);
        }
    }

    const handleDeleteStoreAccount = async id => {
        try {
            await StoreService.removeStoreAccount(axiosPrivate, id);

            setConfirmDialog({
                ...confirmDialog,
                isOpen: false
            })
            const newRecords = records.filter((item) => item._id !== id);
            setRecords(newRecords);
            setNotify({
                isOpen: true,
                message: 'Deleted Successfully',
                type: 'error'
            })
        } catch (err) {
            setConfirmDialog({
                ...confirmDialog,
                isOpen: false
            })
            notifyError(err, setNotify, navigate, location);
        }
    }

    return (
        <Box>
            <IconButton
                onClick={handleClick}
                disabled={store.isActive}
            >
                <MoreHorizIcon />
            </IconButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <ListItem
                    disablePadding
                >
                    <ListItemButton
                        sx={{
                            justifyContent: 'center',
                            px: 1
                        }}
                        onClick={() => {
                            setConfirmDialog({
                                isOpen: true,
                                title: `Are you sure to delete info (orders and redflags) of this store ${store.storeName}?`,
                                subTitle: "You can't undo this operation",
                                onConfirm: () => handleDeleteStoreInfo(store._id)
                            })
                        }}
                    >
                        <ListItemText
                            disableTypography={true}
                            sx={{ fontFamily: "Slabien", fontSize: 14 }}
                            primary="Delete Info"
                        />
                    </ListItemButton>

                </ListItem>

                <ListItem
                    disablePadding
                >
                    <ListItemButton
                        sx={{
                            justifyContent: 'center',
                            px: 1
                        }}
                        onClick={() => {
                            setConfirmDialog({
                                isOpen: true,
                                title: `Are you sure to cancel the account of this store ${store.storeName}?`,
                                subTitle: "You can't undo this operation",
                                onConfirm: () => handleDeleteStoreAccount(store._id)
                            })
                        }}
                    >
                        <ListItemText
                            disableTypography={true}
                            sx={{ fontFamily: "Slabien", fontSize: 14 }}
                            primary="Cancel Account"
                        />
                    </ListItemButton>
                </ListItem>
            </Popover>
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
        </Box >
    );
}

export default React.memo(ActionPopover);

