const AdminUserService = {
    //not called
    updateUser(axiosPrivate, user) {
        return axiosPrivate.put('/api/v1/admin_users', user);
    },
    showMe: function (axiosPrivate) {
        return axiosPrivate.get('/api/v1/admin_users/show_me');
    },
    updateUserName: function (axiosPrivate, name) {
        return axiosPrivate.put('/api/v1/admin_users/username', { name });
    },
    updatePassword: function (axiosPrivate, oldPassword, newPassword) {
        return axiosPrivate.put('/api/v1/admin_users/password', { oldPassword, newPassword });
    },
}

export default AdminUserService;