import React, { useState, useEffect } from 'react';
import { Paper, Box, TableBody, TableRow, TableCell, InputAdornment, Grid, Typography } from '@mui/material';
import { TextInput } from "../../components/common/inputs";
import Search from '@mui/icons-material/Search';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from 'react-router-dom';
import StoreService from "../../services/StoreService";
import { useTable, PageHeader, Notification } from '../../components/common';
import { useBaseStyles } from '../../styles';
import notifyError from '../../errors/notifyError';
import ActionPopover from './ActionPopover';

const headCells = [
    { id: 'storeName', label: 'Store Name' },
    { id: 'website', label: 'Website' },
    { id: 'email', label: 'Owner Email' },
    { id: 'status', label: 'Status' },
    { id: 'action', label: 'Action', disableSorting: true }
]

export default function Stores() {
    const baseClasses = useBaseStyles();
    const [records, setRecords] = useState([]);
    const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });

    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();

    const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'info' });

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        StoreService.getStores(axiosPrivate)
            .then(response => {
                isMounted && setRecords(response.data.stores);
            })
            .catch(err => {
                notifyError(err, setNotify, navigate, location);
            });

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [axiosPrivate, location, navigate]);

    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting
    } = useTable(records, headCells, filterFn);


    const handleSearch = e => {
        let target = e.target;
        setFilterFn({
            fn: items => {
                if (target.value === "")
                    return items;
                else
                    return items.filter(x => x.storeName ? x.storeName.toLowerCase().includes(target.value.toLowerCase()) : false)
            }
        })
    }


    return (
        <>
            <PageHeader
                title="Stores"
            />

            <Paper className={baseClasses.pageContent}>
                <Box sx={{
                    minWidth: 700,
                    display: 'flex',
                    flexDirection: 'column',
                }}>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextInput
                                label="Search Stores"
                                size="small"
                                className={baseClasses.searchInput}
                                InputProps={{
                                    startAdornment: (<InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>)
                                }}
                                onChange={handleSearch}
                            />
                        </Grid>
                    </Grid>
                    <TblContainer>
                        <TblHead />
                        <TableBody>
                            {
                                recordsAfterPagingAndSorting().map(store =>
                                (<TableRow key={store._id}>
                                    <TableCell>{store.storeName}</TableCell>
                                    <TableCell>{store.website}</TableCell>
                                    <TableCell>{store.contact.email}</TableCell>
                                    <TableCell>
                                        {store.isActive ?
                                            <Typography variant="button" className={`${baseClasses.base} ${baseClasses.green_bg}`}
                                                sx={{ textTransform: 'capitalize', fontSize: 13 }} //the textTransform and fontSize properties only change via sx setting, instead of classes
                                            >
                                                Active
                                            </Typography > :
                                            <Typography variant="button" className={`${baseClasses.base} ${baseClasses.grey_bg}`}
                                                sx={{ textTransform: 'capitalize', fontSize: 13 }} //the textTransform and fontSize properties only change via sx setting, instead of classes
                                            >
                                                Inactive
                                            </Typography >
                                        }
                                    </TableCell>
                                    <TableCell>
                                        <ActionPopover
                                            store={store}
                                            records={records}
                                            setRecords={setRecords}
                                            setNotify={setNotify}
                                        />
                                    </TableCell>
                                </TableRow>)
                                )
                            }
                        </TableBody>
                    </TblContainer>
                    <TblPagination />
                </Box>
            </Paper>
            <Notification
                notify={notify}
                setNotify={setNotify}
            />
        </>
    )
}