import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BillingService } from '../../services/BillingService';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAppContext";

const Cancel = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();

  const handleClick = async (e) => {
    e.preventDefault();

    const subscriptionId = location.state.subscriptionId;

    BillingService.cancel(axiosPrivate, {
      subscriptionId,
      storeId: auth.storeId
    }).then(res => {
      navigate("/settings/billing", { replace: true });
    })

  };


  return (
    <div>
      <h1>Cancel</h1>
      <button onClick={handleClick}>Cancel</button>
    </div>
  )
}


export default Cancel;
