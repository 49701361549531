import React, { useState } from 'react';
import AdminForgotPassword from "../../components/public/AdminForgotPassword";
import AdminResetPassword from '../../components/public/AdminResetPassword';

function AdminForgotPasswordAllInOne() {
    const [email, setEmail] = useState('');
    const [isEmailVerified, setIsEmailVerified] = useState(false);

    return (
        isEmailVerified ?
            <AdminResetPassword email={email} /> :
            <AdminForgotPassword email={email} setEmail={setEmail} setIsEmailVerified={setIsEmailVerified} />
    );
}

export default AdminForgotPasswordAllInOne;
