import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function StoreSelect({ stores, handleStoreChange }) {
  const [store, setStore] = useState(null); // displayed in the input

  return (
    <Autocomplete
      options={stores}
      freeSolo
      autoHighlight
      getOptionLabel={(option) => option.storeName}
      size="small"
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          {option.storeName}
        </Box>
      )}
      name="store"
      value={store}
      onChange={(event, newStore) => {
        setStore(newStore);
        handleStoreChange(newStore);
      }}
      style={{ width: 300, backgroundColor: 'white' }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Choose or type a store"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}

